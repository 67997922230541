<template>
  <div>
    <div style="height: 5.625rem"></div>
    <!-- 创作 -->
    <div class="pt-50 pb-40 flex flex-column align-center justify-center">
      <div class="font-26 text-222222 mb-20">创作</div>
      <div class="flex align-center">
        <div class="mr-15 cursorp" @click="goMycreation">
          <!-- <img
            src="@/assets/img/mywork.png"
            alt=""
            width="7.8125rem"
            height="2.1875rem"
          /> -->
          <el-image
            style="width: 7.8125rem; height: 2.1875rem"
            :src="require('@/assets/img/mywork.png')"
            fit="cover"
          ></el-image>
        </div>
        <div class="cursorp" @click="goupwork">
          <!-- <img
            src="@/assets/img/upvideo.png"
            alt=""
            width="6.25rem"
            height="2.1875rem"
          /> -->
          <el-image
            style="width: 6.25rem; height: 2.1875rem"
            :src="require('@/assets/img/upvideo.png')"
            fit="cover"
          ></el-image>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="px-459 py-35 bg-F1F2F3">
      <div class="upbox bg-white">
        <div class="text-center font-20 mb-5">作品上传</div>
        <div class="py-25 border-bottom border-E1E1E1 flex align-center justify-between">
          <div class="font-14 text-222222">视频封面</div>
          <div>
            <el-upload
              class="avatar-uploader"
              action="https://admin.luckiestball.com/api/uploads/uploadFile"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              name="file"
              :before-upload="beforeUpload"
            >
              <!-- <img
                v-if="imgUrl"
                :src="imgUrl"
                width="5.8125rem"
                height="3.3125rem"
                class="avatar"
              /> -->
              <el-image
                style="width: 5.8125rem; height: 3.3125rem"
                v-if="imgUrl"
                :src="imgUrl"
                class="avatar"
                fit="cover"
              ></el-image>
              <el-image
                style="width: 5.8125rem; height: 3.3125rem"
                v-else
                :src="require('@/assets/img/upimg.png')"
                fit="cover"
              ></el-image>
              <!-- <img
                v-else
                src="@/assets/img/upimg.png"
                width="5.8125rem"
                height="3.3125rem"
              /> -->
            </el-upload>
          </div>
        </div>
        <div class="py-25 border-bottom border-E1E1E1 flex align-center justify-between">
          <div class="font-14 text-222222">视频</div>
          <div v-if="videoUrl" class="position-relative">
            <video width="5.8125rem" height="3.3125rem" :src="videoUrl" controls></video>
            <div class="font-14 text-FF524C videoclose" @click="delevideo">
              <i class="el-icon-error"></i>
            </div>
          </div>
          <div v-else>
            <el-upload
              class="avatar-uploader"
              action="https://admin.luckiestball.com/api/uploads/uploadFile"
              :show-file-list="false"
              :on-success="handleVideoSuccess"
              :on-error="handleVideoError"
              name="file"
              :before-upload="handleBeforeUpload"
            >
              <!-- <img
                src="@/assets/img/upvideo2.png"
                width="5.8125rem"
                height="3.3125rem"
              /> -->
              <el-image
                style="width: 5.8125rem; height: 3.3125rem"
                :src="require('@/assets/img/upvideo2.png')"
                fit="cover"
              ></el-image>
            </el-upload>
          </div>
        </div>
        <div class="pt-30 pb-25">
          <div class="font-14 text-222222 mb-20">标题</div>
          <div>
            <el-input type="textarea" :rows="5" placeholder="请输入" v-model="textarea">
            </el-input>
          </div>
        </div>
        <div class="pb-30 flex lable">
          <div class="font-14 text-222222 mr-25 lable_text">标签</div>
          <div class="x-1 flex flex-wrap">
            <div
              v-for="(item, index) in lableList"
              :key="index"
              class="x-3 lable_li"
              :class="item.flag ? 'lable_liact' : ''"
              @click="selelable(item, index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="btn" @click="issue2" v-if="moveid">重新发布</div>
        <div class="btn" @click="issue" v-else>发布</div>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../../utils/req";
export default {
  data() {
    return {
      lableList: [],
      imgUrl: "",
      cover: "",
      videoUrl: "",
      video: "",
      textarea: "",
      moveid: "",
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let moveid = this.$route.query.moveid;
        if (moveid) {
          this.moveid = moveid;
          this.getdata();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getlabel();
    let moveid = this.$route.query.moveid;
    if (moveid) {
      this.moveid = moveid;
      this.getdata();
    }
  },
  methods: {
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("original/releaseInfo", {
          id: this.moveid,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.imgUrl = res.data.whole_img;
          that.cover = res.data.img;
          that.videoUrl = res.data.whole_video;
          that.video = res.data.video;
          that.textarea = res.data.title;
          that.textarea = res.data.title;
          let list = that.lableList;
          let label = res.data.label;
          if (label && label.length) {
            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < label.length; j++) {
                if (list[i].id == label[j].id) {
                  list[i].flag = true;
                }
              }
            }
          }
          that.lableList = list;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 重新发布
    issue2() {
      let that = this;
      let arr = [];
      if (that.lableList && that.lableList.length) {
        that.lableList.forEach((item) => {
          if (item.flag) {
            arr.push(item.id);
          }
        });
      }
      let label = arr.join(",");
      req
        .post("original/againRelease", {
          id: that.moveid,
          title: this.textarea,
          video: this.video,
          label: label,
          img: this.cover,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          this.$router.go(-1);
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 发布
    issue() {
      let that = this;
      let arr = [];
      if (that.lableList && that.lableList.length) {
        that.lableList.forEach((item) => {
          if (item.flag) {
            arr.push(item.id);
          }
        });
      }
      let label = arr.join(",");
      req
        .post("original/release", {
          title: this.textarea,
          video: this.video,
          label: label,
          img: this.cover,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          this.$router.go(-1);
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 选择标签
    selelable(item, index) {
      console.log(item, index);
      this.lableList[index].flag = !this.lableList[index].flag;
    },
    // 获取标签
    getlabel() {
      let that = this;
      req
        .post("original/label", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          if (res.data && res.data.length) {
            res.data.forEach((item) => {
              item.flag = false;
            });
          }
          that.lableList = res.data;
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    // 删除选中视频
    delevideo() {
      this.videoUrl = "";
      this.video = "";
    },
    // 视频
    handleBeforeUpload(file) {
      return new Promise((resolve, reject) => {
        if (file.type !== "video/mp4") {
          this.$message.warning("仅支持mp4格式");
          return reject();
        }
        // this.getVideoDuration(file).then((duration) => {
        //   if (duration > 30) {
        //     this.$message.warning("请上传时长小于30s的视频");
        //     return reject();
        //   } else {
        //     resolve();
        //   }
        // });
        resolve();
      });
    },
    handleVideoError(res, file) {
      console.log("上传失败", res, file);
      this.$message.error(res.msg);
    },
    handleVideoSuccess(res, file) {
      console.log("上传成功", res, file);
      this.video = res.data.filepath;
      this.videoUrl = res.data.preview_url;
      console.log(this.videoUrl);
    },
    // 封面
    beforeUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg";
      const isJPG2 = file.type === "image/png";

      if (!isJPG && !isJPG2) {
        this.$message.error("只能上传图片!");
      }
      return isJPG || isJPG2;
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.cover = res.data.filepath;
      this.imgUrl = res.data.preview_url;
    },
    // 跳转上传作品
    goupwork() {
      this.$router.push({
        path: "/upwork",
        query: { type: "upwork" },
      });
    },
    // 跳转我的创作
    goMycreation() {
      this.$router.push({
        path: "/creationMy",
        query: { type: "creationMy" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.upbox {
  padding: 2.75rem 4.5625rem 2.4375rem 5.3125rem;
}
.videoclose {
  position: absolute;
  top: -20%;
  right: -15%;
  cursor: pointer;
}
.btn {
  width: 10.9375rem;
  height: 2.75rem;
  background: #ff524c;
  box-shadow: 0.125rem 0.1875rem 0.6875rem 0 rgba(144, 144, 144, 0.14);
  border-radius: 1.375rem;
  color: #ffffff;
  line-height: 2.75rem;
  text-align: center;
  font-size: 1.3125rem;
  margin: 0 auto;
  cursor: pointer;
}
.lable {
  &_text {
    width: 2.1875rem;
  }
  &_li {
    width: 8.75rem;
    height: 2.75rem;
    margin-right: 1.5rem;
    margin-bottom: 1.3125rem;
    line-height: 2.75rem;
    text-align: center;
    background: #eeeeee;
    font-size: 0.875rem;
    color: #222222;
    border: 0.125rem solid #eeeeee;

    cursor: pointer;
  }
  &_liact {
    background: #f9f9f9;
    border: 0.125rem solid #ff524c;
    box-shadow: 0.125rem 0.1875rem 0.6875rem 0 rgba(144, 144, 144, 0.14);
    color: #ff524c;
  }
}
</style>
